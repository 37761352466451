html,
body {
    height: 100%;
}
#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

header,
footer {
    padding: 1rem 0;
    color: white;
}
footer {
    text-align: center;
}

main {
    flex: 1;
}
